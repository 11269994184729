import { useFormik } from "formik";
import { filter, find, get, isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  RELATIONSHIP,
} from "utils/constants";
import * as Yup from "yup";

const Information = ({ data, project, translate, onUpdateEmail }) => {
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const children = filter(get(data, "relatedPersons", []), {
    relationship: RELATIONSHIP.CHILD,
  });
  const spouse = get(data, "relatedPersons", []).find(
    ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
  );

  const emailData = find(get(data, "telecoms", []), {
    system: CONTACT_POINT_SYSTEM.EMAIL,
  });

  useEffect(() => {
    setIsEditingEmail(false);
  }, [data]);

  const formik = useFormik({
    initialValues: {
      email: get(
        find(get(data, "telecoms", []), {
          system: CONTACT_POINT_SYSTEM.EMAIL,
        }),
        "value",
        ""
      ),
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(translate.INVALID_EMAIL)
        .required(translate.REQUIRED_EMAIL),
    }),
    onSubmit: (values) => {
      onUpdateEmail(values.email);
      setIsEditingEmail(false);
    },
    enableReinitialize: true,
  });

  return (
    <div className="contact__drawer--container">
      <div className="contact__drawer--container__child">
        <div className="contact__drawer--container__child--header">
          <span>{translate.ADHERENT}</span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.LAST_NAME}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(data, "user.lastname")}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.FIRST_NAME}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(data, "user.firstname")}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.BIRTH_DATE}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {moment(get(data, "user.birthDate")).format(DATE_FORMAT)}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.EMAIL}
          </span>
          <span className="contact__drawer--container__child--info__content email__edit">
            {isEditingEmail ? (
              <form
                onSubmit={formik.handleSubmit}
                className="email__edit--wrapper"
              >
                <Button
                  type="gray--outlined"
                  onClick={() => setIsEditingEmail(false)}
                  className="cancel-btn"
                >
                  <BsArrowLeft />
                </Button>
                <Input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldTouched("email", true);
                  }}
                  onBlur={formik.handleBlur}
                  className="email-input"
                  errors={formik.errors.email}
                  touched={formik.touched.email}
                />
                <Button
                  type="primary"
                  className="update-btn"
                  onClick={formik.handleSubmit}
                  disabled={!formik.isValid}
                >
                  {translate.UPDATE}
                </Button>
              </form>
            ) : (
              <div className="email__edit--display">
                {formik.values.email || "-"}
                <Button
                  type="primary--underline"
                  onClick={() => setIsEditingEmail(true)}
                >
                  {translate.EDIT}
                </Button>
              </div>
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.ZIP_CODE}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(
              find(get(data, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.ZIP_CODE,
              }),
              "value",
              "-"
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.PHONE}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(
              find(get(data, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.PHONE,
              }),
              "value",
              "-"
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.ALREADY_CLIENT}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(data, "fields.isClient", false) ? (
              <FaRegCircleCheck size={24} color="#41AD6A" />
            ) : (
              <RxCross2 size={24} color="#EB5757" />
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.CSS_CLIENT}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(project, "fields.info.cssBeneficiary", false) ? (
              <FaRegCircleCheck size={24} color="#41AD6A" />
            ) : (
              <RxCross2 size={24} color="#EB5757" />
            )}
          </span>
        </div>
      </div>
      <div className="contact__drawer--container__child">
        <div className="contact__drawer--container__child--header">
          <span>{translate.SPOUSE}</span>
          {spouse ? (
            <FaRegCircleCheck size={20} color="#41AD6A" />
          ) : (
            <RxCross2 size={20} color="#EB5757" />
          )}
        </div>
        {spouse && (
          <div className="contact__drawer--container__child--info">
            <span className="contact__drawer--container__child--info__title">
              {translate.SPOUSE_BIRTH_DATE}
            </span>
            <span className="contact__drawer--container__child--info__content">
              {moment(get(spouse, "identity.user.birthDate")).format(
                DATE_FORMAT
              )}
            </span>
          </div>
        )}
      </div>
      <div className="contact__drawer--container__child">
        <div className="contact__drawer--container__child--header">
          <span>{translate.CHILDREN}</span>
          {isEmpty(children) ? (
            <RxCross2 size={20} color="#EB5757" />
          ) : (
            <FaRegCircleCheck size={20} color="#41AD6A" />
          )}
        </div>
        {!isEmpty(children) && (
          <>
            <div className="contact__drawer--container__child--info">
              <span className="contact__drawer--container__child--info__title">
                {translate.CHILDREN_COUNT}
              </span>
              <span className="contact__drawer--container__child--info__content">
                {children.length}
              </span>
            </div>
            {children.map((_, index) => (
              <div
                key={`child-${index}`}
                className="contact__drawer--container__child--info"
              >
                <span className="contact__drawer--container__child--info__title">
                  {translate.CHILDREN_BIRTH_DATE} {index + 1}
                </span>
                <span className="contact__drawer--container__child--info__content">
                  {moment(get(_, "identity.user.birthDate")).format(
                    DATE_FORMAT
                  )}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default Information;
