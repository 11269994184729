import Subscriptions from "entities/Subscriptions";
import { get, isEmpty } from "lodash";
import Loading from "shared/components/Spin";
import { useQuery } from "shared/hooks/useApi";
import { PROJECT } from "utils/api/graphql/queries/projects";
import { TRACER_TYPES } from "utils/constants";
import View from "./View";

const List = ({ onClose, project, onChangeStatus }) => {
  const { data, loading } = useQuery(PROJECT, {
    variables: {
      where: {
        id: project,
        statuses: {
          status: {
            process: {
              flows: {
                tracers: {
                  type: TRACER_TYPES.MANUAL,
                },
              },
            },
          },
        },
      },
    },
  });

  if (isEmpty(data) || loading) return <Loading />;

  return (
    <Subscriptions
      filter={{ where: { project: { id: project } } }}
      View={View}
      NoData={() => null}
      onClose={onClose}
      project={get(data, "project", {})}
      onChangeStatus={onChangeStatus}
    />
  );
};

export default List;
