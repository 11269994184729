import { useLazyQuery, useQuery } from "@apollo/client";
import cuid from "cuid";
import { get } from "lodash";
import { useState } from "react";
import Loading from "shared/components/Spin";
import { useMutation } from "shared/hooks/useApi";
import { CREATE_ATTACHMENT } from "utils/api/graphql/mutations/attachment";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { UPDATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { PROCESS_STATUSES } from "utils/api/graphql/queries/process-statuses";
import { SUBSCRIPTIONS } from "utils/api/graphql/queries/subscription";
import {
  DOCUMENTS_TYPES,
  MODAL_STATES,
  PROCESS_TAGS,
  SUBSCRIPTION_STATUSES,
} from "utils/constants";
import View from "./View";

const SignProjectDrawer = ({ onClose, project = {} }) => {
  const filter = { where: { project: { id: project.id } } };
  const { data, loading } = useQuery(SUBSCRIPTIONS, { variables: filter });
  const [state, setState] = useState(MODAL_STATES.INITIAL);
  const [getProcessStatuses] = useLazyQuery(PROCESS_STATUSES);
  const [addAttachment] = useMutation(CREATE_ATTACHMENT);
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: SUBSCRIPTIONS,
        variables: filter,
      },
    ],
  });
  const [updateProject] = useMutation(UPDATE_PROJECT);

  const onSign = ({ subscriptionId, signedDate, attachments }) => {
    Object.entries(attachments).map(([key, value]) =>
      value.map(({ name, contentType, base64 }) =>
        addAttachment({
          variables: {
            data: {
              id: cuid(),
              subscription: { id: subscriptionId },
              name,
              content: base64,
              contentType,
              type: DOCUMENTS_TYPES[key],
            },
          },
        })
      )
    );
    const processes = get(project, "statuses", []).map(
      ({ status }) => status.process.id
    );
    updateSubscription({
      variables: {
        where: { id: subscriptionId },
        data: { status: SUBSCRIPTION_STATUSES.SIGNED, signedDate },
      },
      onCompleted: () => {
        getProcessStatuses({
          variables: {
            where: { tag: PROCESS_TAGS.SIGNED },
            isIn: { process: { id: processes } },
          },
          onCompleted: ({ processStatuses }) => {
            updateProject({
              variables: {
                where: { id: project.id },
                data: {
                  expired: false,
                  statuses: get(processStatuses, "data", []).map(({ id }) => ({
                    id: cuid(),
                    status: { id },
                  })),
                },
              },
              onCompleted: () => setState(MODAL_STATES.DONE),
            });
          },
        });
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <View
      onClose={onClose}
      projectId={project.id}
      onSign={onSign}
      data={get(data, "subscriptions.data", [])}
      state={state}
      setState={setState}
    />
  );
};

export default SignProjectDrawer;
